<template>
  <div>
    <top :name="text" />
    <div class="search_div">
      <van-search
        @click="is"
        style="position: relative; z-index: 9999; flex: 1"
        shape="round"
        background="none"
        v-model="search_value"
        placeholder="搜索商品名称或类型"
      />
      <div @click="searct_fun()" class="sou">搜索</div>
    </div>
    <van-tree-select
      height="auto"
      :items="items"
      :main-active-index.sync="active"
      @click-nav="you"
    >
      <template #content>
        <!-- v-if="active === 1" -->
        <div style="padding: 0.4rem 0rem 0.4rem 0.3rem" class="brand-list">
          <!--  -->
          <div
            class="product-nav"
            v-for="(item, index) in item_arr_id"
            :key="index"
            @click="zuo((id = item.categoryId))"
          >
            <!-- {{item_arr_id[0].childCats[0].img}} -->
            <img :src="item.img" alt="" />
            <div class="secondT">{{ item.categoryName }}</div>
          </div>
        </div>
      </template>
    </van-tree-select>
    <bottom />
  </div>
</template>

<script lang="ts">
import top from "../components/filltop.vue";
import bottom from "../components/bottom.vue";
export default {
  name: "fenlei",
  components: {
    bottom,
    top,
  },
  data() {
    return {
      text: "商品分类",
      active: 0,
      items: [],
      arr: [],
      item_arr_id: [],
      page: 1,
      search_value: null,
    };
  },
  mounted() {},
  methods: {
    you(e) {
      console.log("id");
      var set_arr = new Set();
      let arrgort = this.arr.commodity[0];
      for (var v = 0; v < arrgort.length; v++) {
        for (var a = 0; a < arrgort[v].length; a++) {
          //   // 右侧 添加数据时增加一条判断，屏蔽南航等字样，后期转到后台
          if (this.arr.commodity[0][v][a].parentId == this.items[e].id) {
            this.item_arr_id = this.arr.commodity[0][v][a];
            set_arr.add(this.arr.commodity[0][v][a].childCats);
          }
        }
      }

      var item_arr = [...set_arr];
      var sv = new Set();
      for (var b = 0; b < item_arr.length; b++) {
        for (var g = 0; g < item_arr[b].length; g++) {
          sv.add(item_arr[b][g]);
        }
      }
      var bet = [...sv];
      this.item_arr_id = bet;
    },
    searct_fun() {
      this.$router.push({
        name: "productlist",
        params: { success: this.search_value, isfalse: "false" },
      });
    },
    zuo(item) {
      this.$router.push({
        name: "productlist",
        params: { success: item },
      });
      // this.$https(
      //   "goods/list",
      //   "page=" + this.page + "&value=" + item +"&is_id="+'true',
      //   "get"
      // ).then((res) => {
      //   if (res.data.code == 200) {
      //     this.$router.push({
      //       name: "productlist",
      //       params: { success: res.data },
      //     });
      //   } else {
      //     console.log("请求失败  请重新检查接口");
      //   }
      // });
    },
  },
  created() {
    // 详情请求
    this.$https("class/classtion", "", "post")
      .then((res) => {
        if (res.data.code == 200) {
          console.log(res.data);
          this.arr = res.data.data;
          for (var a = 0; a < this.arr.calssname.gory.length; a++) {
            var item = this.arr.calssname.gory[a];
            let array_text = [
              "航空周边",
              "电子卡券",
              "保险",
              "乡村振兴",
              "旅游度假",
            ];
            if (!array_text.includes(item.categoryName)) {
              this.items.push({
                text: item.categoryName,
                id: item.categoryId,
              });
            }
          }
          this.you(0);
        } else {
          // 提示错误
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
};
</script>

<style scoped>
.van-tree-select__nav >>> .van-tree-select__nav-item {
  padding: 5.333333vw 3.2vw;
}
.product-nav {
  width: 30%;
  margin: 0 2% 0 0;
  display: inline-block;
  text-align: center;
}
.product-nav img {
  width: 1.4rem;
  height: 1.4rem;
  display: inline-block;
  border: 0.01rem solid #e5e5e5;
}
.secondT {
  padding: 0.2rem 0;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  width: 95%;
}
.van-tree-select >>> .van-tree-select__content {
  min-height: auto;
  max-height: 22.8rem;
  overflow: auto;
  background-color: whitesmoke;
}
.search_div {
  display: flex;
  align-items: center;
}
.sou {
  font-size: 0.3rem;
  margin-right: 0.2rem;
  z-index: 9999;
  padding: 0.1rem 0.3rem 0.1rem 0.3rem;
  color: white;
  background: #241ec7;
  border-radius: 0.2rem;
}
</style>